import * as yup from 'yup';
import {RegexPatterns} from "@/common/constants/regexPatterns";
import {checkIfEmpty} from "@/common/utils/utils";
import {t} from 'i18next'

export const getInitialValues = (courierItem: any): any => {
    return {
        firstName: checkIfEmpty(courierItem?.firstName),
        lastName: checkIfEmpty(courierItem?.lastName),
        middleName: checkIfEmpty(courierItem?.middleName),
        phone: checkIfEmpty(courierItem?.phone),
        iin: checkIfEmpty(courierItem?.iin),
        hasCar: courierItem?.hasCar ?? null,
        contractNumber: checkIfEmpty(courierItem?.contractNumber),
        contractStartDate: courierItem?.contractStartDate ?? "*",
        contractEndDate: courierItem?.contractEndDate ?? "*",
        courierAddress: checkIfEmpty(courierItem?.courierAddress),
        comments: checkIfEmpty(courierItem?.comments),
        birthdate: courierItem?.birthdate ?? "*",
        city: courierItem?.city ?? {
            id: "",
            name: ""
        },
    }
}



const firstName = yup.string()
    .required(t('couriersPage.enter_firstName'))
    .matches(RegexPatterns.names, t('couriersPage.name'))
const lastName = yup.string()
    .required(t('couriersPage.enter_lastName'))
    .matches(RegexPatterns.names, t('couriersPage.lastName'))
const middleName = yup.string()
    .matches(RegexPatterns.names, t('couriersPage.middleName'))
const phone = yup.string()
    .required(t('couriersPage.validation.phone'))
    .matches(/^\d{11}$/, `${t('couriersPage.validation.phoneFormat')}`)
    .matches(RegexPatterns.phoneSize, `${t('couriersPage.validation.phone')}`)
const iin = yup.string()
    .matches(RegexPatterns.iin, t('couriersPage.validation.iinFormat'))
const hasCar = yup.mixed().nonNullable(t('couriersPage.enter_transport'))

const contractNumber = yup.string()
    .matches(/^\d+$/, t('couriersPage.incorrectFormat'))
    .max(256, t('couriersPage.validation.tooLong', {field: t('couriersPage.contractNumber')}))
const contractStartDate = yup.string()
const contractEndDate = yup.string()
const birthdate = yup.string()
const courierAddress = yup.string()
    .max(128, t('couriersPage.validation.tooLong', {field: t('couriersPage.courierAddress')}))
const comments = yup.string()
    .max(256, t('couriersPage.validation.tooLong', {field: t('couriersPage.comments')}))
const city = yup.object().shape({
    id: yup.number().required(t('couriersPage.validation.cityRequired')),
    name: yup.string().required(t('couriersPage.validation.cityRequired'))
})

export const CourierModalSchema = {
    custom: yup.object().shape({
        firstName,
        lastName,
        middleName,
        phone,
        iin,
        hasCar,
        contractNumber,
        contractStartDate,
        contractEndDate,
        birthdate,
        courierAddress,
        city,
        comments
    })
}