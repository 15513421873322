import {makeAutoObservable} from "mobx";

import {Persistable} from "../storage/persistable";
import { importForm, SortOption } from '@/common/models/util';
import {OrderItem, OrderSearchQuery} from "@/common/models/order";

class OrderStore implements Persistable{
    persistedSortOptions: SortOption[] = []
    persistedFilterQuery: OrderSearchQuery = {
        deliveryStatuses: "NEW,READY_FOR_PICKUP,PICKUP_COURIER_ASSIGNED,ON_WAY_TO_WAREHOUSE,DELIVERED_TO_WAREHOUSE,ACCEPTED_BY_WAREHOUSE,PENDING_CLIENT_CLARIFICATION,MARKING_AT_WAREHOUSE,MARKED_AT_WAREHOUSE,SORTING_AT_WAREHOUSE,SORTED_AT_WAREHOUSE,READY_FOR_DELIVERY,DELIVERY_COURIER_ASSIGNED,DELIVERY_IN_PROGRESS,DELIVERED_BY_COURIER,READY_FOR_DEPARTURE_DELIVERY,DELIVERY_TO_DEPARTURE,DELIVERY_IN_TRANSIT,READY_FOR_ARRIVAL_DELIVERY,PICKUP_COURIER_ARRIVAL_ASSIGNED,DELIVERY_COURIER_ARRIVAL_ASSIGNED"
    }
    // TODO: Make dto to take only needed fields after implementing all functions
    selectedOrders: OrderItem[] = []
    importForm: importForm = {
        step: 0,
        type: "",
        fileName: "",
        fileSize: '',
        orders: [],
        brokenOrders: [],
        initialOrders: []
    }
    constructor() {
        makeAutoObservable(this, {}, {autoBind: true})
        this.loadFromLocalStorage()
    }

    setImportForm(orders) {
        this.importForm = orders;
        localStorage.setItem('importForm', JSON.stringify(this.importForm));
    }
    setSelectedOrders(orders) {
        this.selectedOrders = orders
        localStorage.setItem('selectedOrders', JSON.stringify(this.selectedOrders))
    }

    persistSortOptions(options) {
        this.persistedSortOptions = options
        localStorage.setItem('sortOptions', JSON.stringify(this.persistedSortOptions))
    }

    persistFilterQuery(options) {
        this.persistedFilterQuery = options
        localStorage.setItem('filterQuery', JSON.stringify(this.persistedFilterQuery))
    }



    loadFromLocalStorage(): void {
        const sortOptions = localStorage.getItem('sortOptions')
        const filterQuery = localStorage.getItem('filterQuery')
        const selectedOrders = localStorage.getItem('selectedOrders')
        const importForm = localStorage.getItem('importForm')

        if (sortOptions) {
            this.persistedSortOptions = JSON.parse(sortOptions)
        }
        if (importForm && importForm !== "undefined") {
            this.importForm = JSON.parse(importForm)
        }
        if(filterQuery){
            this.persistedFilterQuery = JSON.parse(filterQuery)
        }
        if(selectedOrders){
            this.selectedOrders = JSON.parse(selectedOrders)
        }
    }
}

const orderStore = new OrderStore()

export default orderStore