import React, {FC, useCallback, useState} from 'react';
import {Formik, Form} from 'formik';
import {Button, Dropdown, Container, Row, Col, Modal, Spinner} from "react-bootstrap";
import {Calendar} from "react-calendar";
import {formatDate} from "date-fns";

import {Courier} from "@/common/models/courier";
import CourierService from "@/common/api/CourierService";
import { City } from "@/common/models/address"
import {CourierModalSchema, getInitialValues} from "@/pages/courier/components/Form/CourierValidation";
import {Formats} from "@/common/constants/dateFormat";
import Input from '@/components/controls/input/Input'
import InputPhoneMasked from '@/components/controls/input/InputPhoneMasked'
import CityAsyncSelect from '@/components/controls/select/CityAsyncSelect'
import DefaultButton from "@/components/buttons/DefaultButton";
import CourierDeleteModal from "./CourierDeleteModal";
import { useMutation } from 'react-query';
import { buildCourier } from '@/common/utils/utils';
import { ResponseError } from 'superagent';
import { useTranslation } from 'react-i18next';


interface CouriersFormProps {
    courierItem?: Courier,
    showModal: boolean,
    deleteMode?: boolean,
    setDeleteMode?: (flag: boolean) => void,
    setShowModal: (boolean) => void,
    onSubmit?: () => void,
}

const CouriersForm: FC<CouriersFormProps> = ({
     courierItem = null,
     showModal,
     setShowModal,
     deleteMode = false,
     setDeleteMode = () => {},
     onSubmit = () => window.location.reload(),
     }) => {
    const {t} = useTranslation()
    const [editMode, setEditMode] = useState<boolean>(false)
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(deleteMode)
    const [error, setError] = useState(null)
    const [city, setCity] = useState<City>(courierItem?.city ?? null)
    const [dates, setDates] = useState({
        contractStartDate: courierItem?.contractStartDate ?? null,
        contractEndDate: courierItem?.contractEndDate ?? null,
        birthdate: courierItem?.birthdate ?? null,
    })

    /* Flags */
    const [showDates, setShowDates] = useState({
        showContractStartCalendar: false,
        showContractEndCalendar: false,
        showBirthDate: false
    })

    const handleContractStartDateChange = useCallback((date, callback) => {
        const strDate = formatDate(date, Formats.DATE_YMD)
        callback("contractStartDate", strDate)
        setDates(prev => ({
            ...prev,
            contractStartDate: date
        }))
        setShowDates(prev => ({
            ...prev,
            showContractStartCalendar: !prev?.showContractStartCalendar
        }))
    }, [setDates, setShowDates])
    const handleContractEndDateChange = useCallback((date, callback) => {
        const strDate = formatDate(date, Formats.DATE_YMD)
        callback("contractEndDate", strDate)
        setDates(prev => ({
            ...prev,
            contractEndDate: date
        }))
        setShowDates(prev => ({
            ...prev,
            showContractEndCalendar: !prev?.showContractEndCalendar
        }))
    }, [setShowDates, setDates])
    const handleBirthDateChange = useCallback((date, callback) => {
        const strDate = formatDate(date, Formats.DATE_YMD)
        callback("birthdate", strDate)
        setDates(prev => ({
            ...prev,
            birthdate: date
        }))
        setShowDates(prevState => ({
            ...prevState,
            showBirthDate: false
        }))
    }, [setDates, setShowDates])
    const handleCityChange = useCallback((city, callback) => {
        callback("city.id", city.value)
        callback("city.name", city.label)
        setCity({id: city.value, name: city.label})
    }, [setCity])

    const handleClose = () => {
        setShowModal(false)
        setDeleteMode(false)
    }

    const { mutate: handleCreate, isLoading: isCreating } = useMutation<void, ResponseError, Courier>(['createCourier'], (values) => {
        const newCourier = buildCourier({values, city, dates})
        return CourierService.createCourier(newCourier)
    }, {
        onSuccess: () => {
            setShowModal(false)
            onSubmit()
        },
        onError: (e) => {
            setError(e?.response?.body?.errorMessage)
        }
    })

    const { mutate: handleSave, isLoading: isSaving } = useMutation<void, ResponseError, Courier>(['saveCourier'],  (values) => {
        const updatedCourier = buildCourier({values, city, dates})
        return CourierService.updateCourier(courierItem.id, updatedCourier)

    }, {
        onSuccess: () => {
            setEditMode(false)
            onSubmit()
        },
        onError: (e) => {
            setError(e?.response?.body?.errorMessage)
        }
    })

    const { mutate: handleDelete, isLoading: isDeleting } = useMutation<void, ResponseError, void>(['deleteCourier'],  () => {
        return CourierService.deleteCourier(courierItem.id);
    }, {
        onSuccess: () => {
            setShowDeleteModal(false)
            onSubmit()
        },
        onError: (e) => {
            setError(e?.response?.body?.errorMessage)
        },
    })
    const isLoading = !!(isDeleting || isSaving || isCreating)
    return (
        <div>
                {showDeleteModal ? (
                    <CourierDeleteModal
                        courierItem={courierItem}
                        onDelete={handleDelete}
                        setShowModal={setShowDeleteModal}
                        setError={setError}
                    />
                ) : (
                    <Modal show={showModal} dialogClassName="wide-modal">
                        <Modal.Header className="d-flex w-100 justify-content-between">
                            <Modal.Title>{t('couriersPage.createCourierAccount')}</Modal.Title>
                            <Button className="mr-5" variant="light" onClick={handleClose}>
                                X
                            </Button>
                        </Modal.Header>
                        <Modal.Body>
                            <Container>
                                <Formik
                                    initialValues={getInitialValues(courierItem)}
                                    validationSchema={CourierModalSchema.custom}
                                    onSubmit={(values: Courier) => {
                                        courierItem ? (handleSave(values)) : (handleCreate(values))
                                    }}>
                                    {({values, setFieldValue, errors, touched}) => (
                                        <Form key={"CourierModal"}>
                                            <Row className="d-flex mb-3 align-items-start justify-content-around">
                                                <Col md={4}>
                                                    <Input
                                                        id={"firstName"}
                                                        label={t('couriersPage.firstName')}
                                                        name={"firstName"}
                                                        placeholder={t('couriersPage.enter_firstName')}
                                                        disabled={!editMode && courierItem}
                                                        required
                                                    />
                                                </Col>
                                                <Col md={4}>
                                                    <Input
                                                        id={"lastName"}
                                                        label={t('couriersPage.lastName')}
                                                        name={"lastName"}
                                                        placeholder={t('couriersPage.enter_lastName')}
                                                        disabled={!editMode && courierItem}
                                                        required
                                                    />
                                                </Col>
                                                <Col md={4}>
                                                    <Input
                                                        id={"middleName"}
                                                        label={t('couriersPage.middleName')}
                                                        name={"middleName"}
                                                        placeholder={t('couriersPage.enter_middleName')}
                                                        disabled={!editMode && courierItem}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="d-flex mb-3 align-items-start justify-content-around">
                                                <Col md={4}>
                                                    <InputPhoneMasked
                                                        className="py-2"
                                                        id={`phone`}
                                                        name={`phone`}
                                                        label={t('couriersPage.phoneNumber')}
                                                        required
                                                        disabled={courierItem && !editMode}
                                                        onMaskChange={(value: string) => {
                                                            setFieldValue(`phone`, value)
                                                        }}
                                                    />
                                                </Col>
                                                <Col md={4}>
                                                    <Input
                                                        id={"iin"}
                                                        label={t('couriersPage.iin')}
                                                        name={"iin"}
                                                        placeholder={t('couriersPage.enter_iin')}
                                                        disabled={!editMode && courierItem}
                                                    />
                                                </Col>
                                                <Col md={4}>
                                                    <br/>
                                                    <Dropdown className="w-100">
                                                        <Dropdown.Toggle
                                                            className="w-100"
                                                            id={"hasCar-dropdown"}
                                                            variant={"primary"}
                                                            disabled={!editMode && courierItem}
                                                        >
                                                            {values.hasCar === null ? (t('couriersPage.transport')) : (values.hasCar ? (t('couriersPage.has')) : (t('couriersPage.absent')))}
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu
                                                            className="w-100"
                                                        >
                                                            <Dropdown.Item
                                                                className="w-100 text-center"
                                                                onClick={() => setFieldValue("hasCar", true)}>{t('couriersPage.has')}</Dropdown.Item>
                                                            <Dropdown.Item
                                                                className="w-100 text-center"
                                                                onClick={() => setFieldValue("hasCar", false)}>{t('couriersPage.absent')}</Dropdown.Item>
                                                            <Dropdown.Item
                                                                className="w-100 text-center"
                                                                style={{backgroundColor: "#fe3623", color: "white"}}
                                                                onClick={() => setFieldValue("hasCar", null)}
                                                            >
                                                                {t('couriersPage.clear')}
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    {touched.hasCar && errors?.hasCar && (
                                                        <span
                                                            className="text-danger">{(errors?.hasCar as string)}</span>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row className="d-flex mb-3 align-items-start justify-content-around">
                                                <Col md={4}>
                                                    <Input
                                                        id={"courierAddress"}
                                                        label={t('couriersPage.courierAddress')}
                                                        name={"courierAddress"}
                                                        placeholder={t('couriersPage.enter_courierAddress')}
                                                        disabled={!editMode && courierItem}
                                                    />

                                                </Col>
                                                <Col md={4}>
                                                    <span>{t('couriersPage.city')}<span className={"text-danger"}>*</span></span>
                                                    <div className="my-1">
                                                        <CityAsyncSelect
                                                            name="city"
                                                            readOnly={!editMode && courierItem}
                                                            defaultValue={city ? {
                                                                value: city?.id,
                                                                label: city?.name
                                                            } : undefined}
                                                            onChange={(option) => handleCityChange(option, setFieldValue)}
                                                        />
                                                        {touched.city && errors?.city?.name && (
                                                            <span
                                                                className="text-danger">{errors?.city?.name}</span>
                                                        )}
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <Input
                                                        id={"contractNumber"}
                                                        label={t('couriersPage.contractNumber')}
                                                        name={"contractNumber"}
                                                        placeholder={t('couriersPage.enter_contractNumber')}
                                                        disabled={!editMode && courierItem}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="d-flex mb-3 align-items-start justify-content-around">
                                                <Col md={4}>
                                                    <Input
                                                        id={"contractStartDate"}
                                                        label={t('couriersPage.contractStartDate')}
                                                        name={"contractStartDate"}
                                                        placeholder={""}
                                                        as="button"
                                                        disabled={!editMode && courierItem}
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            setShowDates(prev => ({
                                                                ...prev,
                                                                showContractStartCalendar: !prev?.showContractStartCalendar
                                                            }))
                                                        }}
                                                    >
                                                        {dates.contractStartDate === null ? (
                                                            <span>{t('couriersPage.enter_contractStartDate.start')} <br/> {t('couriersPage.enter_contractStartDate.end')}</span>) : (values.contractStartDate)}
                                                    </Input>
                                                    {showDates.showContractStartCalendar && (
                                                        <div className="d-flex align-items-center flex-column">
                                                            <Button
                                                                className="w-75"
                                                                variant={"info"}
                                                                onClick={() => {
                                                                    setFieldValue("contractStartDate", "*")
                                                                    setDates(prev => ({
                                                                        ...prev,
                                                                        contractStartDate: null
                                                                    }))
                                                                }}
                                                            >
                                                                <span className="text-light">{t('couriersPage.clear')}</span>
                                                            </Button>
                                                            <Calendar
                                                                locale={'ru'}
                                                                key="contractStartDateCalendar"
                                                                className="border-none"
                                                                onChange={(value: Date) => handleContractStartDateChange(value, setFieldValue)}
                                                            />
                                                        </div>
                                                    )}
                                                </Col>
                                                <Col md={4}>
                                                    <Input
                                                        id={"contractEndDate"}
                                                        label={t('couriersPage.contractEndDate')}
                                                        name={"contractEndDate"}
                                                        placeholder={""}
                                                        as="button"
                                                        disabled={!editMode && courierItem}
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            setShowDates(prev => ({
                                                                ...prev,
                                                                showContractEndCalendar: !prev?.showContractEndCalendar
                                                            }))
                                                        }}
                                                    >{dates.contractEndDate === null ? (
                                                        <span>{t('couriersPage.enter_contractEndDate.start')} <br/> {t('couriersPage.enter_contractEndDate.end')}</span>) : (values.contractEndDate)}</Input>
                                                    {showDates.showContractEndCalendar && (
                                                        <div className="d-flex align-items-center flex-column">
                                                            <Button
                                                                className="w-75"
                                                                variant={"info"}
                                                                onClick={() => {
                                                                    setFieldValue("contractEndDate", "*")
                                                                    setDates(prev => ({
                                                                        ...prev,
                                                                        contractEndDate: null
                                                                    }))
                                                                }}
                                                            >
                                                                <span className="text-light">{t('couriersPage.clear')}</span>
                                                            </Button>
                                                            <Calendar
                                                                locale={'ru'}
                                                                key="contractEndDateCalendar"
                                                                className="border-none"
                                                                onChange={(value: Date) => handleContractEndDateChange(value, setFieldValue)}
                                                            />
                                                        </div>

                                                    )}
                                                </Col>
                                                <Col md={4}>
                                                    <Input
                                                        id={"birthdate"}
                                                        label={t('couriersPage.birthdate')}
                                                        name={"birthdate"}
                                                        placeholder={""}
                                                        as="button"
                                                        disabled={!editMode && courierItem}
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            setShowDates(prev => ({
                                                                ...prev,
                                                                showBirthDate: !prev?.showBirthDate
                                                            }))
                                                        }}
                                                    >{dates.birthdate === null ? (
                                                        <span>{t('couriersPage.enter_birthdate.start')} <br/> {t('couriersPage.enter_birthdate.end')}</span>) : (values.birthdate)}
                                                    </Input>
                                                    {showDates.showBirthDate && (
                                                        <div className="d-flex align-items-center flex-column">
                                                            <Button
                                                                className="w-75"
                                                                variant={"info"}
                                                                onClick={() => {
                                                                    setFieldValue("birthdate", "*")
                                                                    setDates(prev => ({
                                                                        ...prev,
                                                                        birthdate: null
                                                                    }))
                                                                }}
                                                            >
                                                                <span className="text-light">{t('couriersPage.clear')}</span>
                                                            </Button>
                                                            <Calendar
                                                                locale={'ru'}
                                                                key="birthdateCalendar"
                                                                className="border-none"
                                                                onChange={(value: Date) => handleBirthDateChange(value, setFieldValue)}
                                                            />
                                                        </div>

                                                    )}
                                                </Col>
                                            </Row>
                                            <Row className="d-flex mb-3 justify-content-center align-items-center">
                                                <Col md={10}>
                                                    <Input
                                                        id={"comments"}
                                                        label={t('couriersPage.comments')}
                                                        name={"comments"}
                                                        placeholder={t('couriersPage.enter_comments')}
                                                        as={"textarea"}
                                                        disabled={!editMode && courierItem}
                                                        style={{minHeight: "7rem"}}
                                                        onChange={(e) => {
                                                            setFieldValue("comments", e.target.value)
                                                        }}
                                                        value={values.comments}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="d-flex flex-column mb-3 justify-content-center align-items-center">
                                                {error && (
                                                    <Col
                                                        className="w-100 mt-2 mb-2 d-block text-danger text-center">{JSON.stringify(error)}</Col>
                                                )}
                                                <Row className="d-flex justify-content-center w-25 flex-nowrap">
                                                    {
                                                        isLoading ? (<Spinner/>) : (
                                                            courierItem === null ? (
                                                                <DefaultButton variant={"primary"}
                                                                               isLoading={isLoading}
                                                                               submit>
                                                                    {t('couriersPage.createCourier')}
                                                                </DefaultButton>
                                                            ) : (
                                                                editMode ? (
                                                                    <>
                                                                        <DefaultButton variant={"primary"}
                                                                                       className="mx-3"
                                                                                       isLoading={isLoading}
                                                                                       submit
                                                                        >{t('couriersPage.saveChanges')}</DefaultButton>
                                                                        <DefaultButton variant={"danger"}
                                                                                       className="mx-3"
                                                                                       isLoading={isLoading}
                                                                                       onClick={() => setEditMode(false)}>{t('couriersPage.cancelEdit')}</DefaultButton>

                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <Button variant={"primary"}
                                                                                       className="mx-3 border-rounded-2 btn-text"
                                                                                       onClick={() => setEditMode(true)}
                                                                        >{t('couriersPage.edit')}</Button>
                                                                        <DefaultButton variant={"danger"}
                                                                                       className="mx-3 text-light"
                                                                                       isLoading={isLoading}
                                                                                       onClick={() => {
                                                                                           setShowDeleteModal(true)
                                                                                       }}
                                                                        >{t('couriersPage.deleteCourier')}</DefaultButton>
                                                                    </>
                                                                )
                                                            )
                                                        )
                                                    }
                                                </Row>
                                            </Row>

                                        </Form>
                                    )}
                                </Formik>
                            </Container>
                        </Modal.Body>
                    </Modal>
                )}
        </div>
    );
};


export default CouriersForm;