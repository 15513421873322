import React from 'react';
import AntHeading from '@/components/controls/heading/antHeading';
import { useTranslation } from 'react-i18next';
import ClientHandbookTable from '@/pages/client-handbook/components/clientHandbookTable';
import { useQuery } from 'react-query';
import { ClientService } from '@/common/api/ClientService';
import { ResponseError } from 'superagent';
import { handbookClientItem } from '@/common/models/handbook';

const ClientHandbookViewPage = () => {
  const { data: clientItems, isLoading } = useQuery<void, ResponseError, handbookClientItem[], string[]>(['fetchClients'],
    () => ClientService.getClients())
  const { t } = useTranslation();
  return (
    <div style={{display: 'flex', flexDirection: 'column', height: '95%'}}>
      <AntHeading
        size={'h2'}
        style={{
          marginTop: '28px',
          marginBottom: '25px'
        }}
      >
        {t('clientHandbookPage.pageTitle')} {clientItems?.items?.length && <>({clientItems?.items?.length})</>}
      </AntHeading>
      <ClientHandbookTable clients={clientItems?.items} isLoading={isLoading}/>
    </div>
  );
};

export default ClientHandbookViewPage;