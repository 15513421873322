import React, { FC } from 'react';
import { Button } from 'antd';
import clsx from 'clsx';
import { BaseButtonProps } from 'antd/es/button/button';

import s from './index.module.scss';
import { buttonTypes } from '@/common/utils/utilTypes';


const AntButton: FC<AntButtonProps> = ({small = false, type = 'primary', className, styles, ...props}) => {
  return (
    <div className={clsx(className, s['custom-ant-button-wrapper'])} style={{ ...styles }}>
      <Button
        className={clsx(className,
          s[`custom-ant-button`],
          s[`custom-${type.toString()}-ant-button`],
          {
            [s.customSmallAntButton]: small
          }
        )}
        {...props}
      />
    </div>
  );
};

interface AntButtonProps extends Omit<BaseButtonProps, 'type'> {
  type?: keyof typeof buttonTypes,
  small?: boolean
  styles?: React.CSSProperties,
  className?: string
}

export default AntButton;