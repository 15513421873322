import React, { FC } from 'react';
import { Popconfirm, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { Roles } from '@/common/constants/roles';
import { ImportTableOrder } from '@/common/models/order';
import checkSquare from '@/assets/icons/import/check-square.svg';
import greyCheck from '@/assets/icons/import/grey-check.svg';
import closeSquare from '@/assets/icons/import/close-square.svg';
import editIcon from '@/assets/icons/import/edit.svg';
import closeIcon from '@/assets/icons/import/close.svg';

const OperationsColumn: FC<OperationsColumnProps> = ({
                                                       record,
                                                       isEditing,
                                                       validationStatus,
                                                       save,
                                                       cancel,
                                                       editingKey,
                                                       hasAnyRole,
                                                       editOrder,
                                                       deleteOrder,
                                                     }) => {
  const { t } = useTranslation();
  const editable = isEditing(record);  // Determines if the record is in edit mode
  const isValid = validationStatus[record.key];
  return editable ? (
    <span>
            {
              isValid !== false ? (
                <Popconfirm
                  okButtonProps={{
                    style: {
                      padding: '0.8rem',
                    },
                  }}
                  cancelButtonProps={{
                    style: {
                      padding: '0.8rem',
                    },
                  }}
                  okText={<Typography.Title style={{ color: 'white', marginBottom: 0 }}
                                            level={5}>{t('ordersImportPage.table.ok')}</Typography.Title>}
                  cancelText={<Typography.Title style={{ marginBottom: 0 }}
                                                level={5}>{t('ordersImportPage.table.cancel')}</Typography.Title>}
                  title={<Typography.Title level={4}>{t('ordersImportPage.table.confirmSave')}</Typography.Title>}
                  onConfirm={() => save(record.key)}
                >
                  <img className='edit-img'
                       src={checkSquare}
                       alt='check-square' />
                </Popconfirm>
              ) : (
                <img className='edit-img error'
                     src={greyCheck}
                     alt='check-square' />
              )
            }
      <img onClick={cancel} className='edit-img' src={closeSquare} alt='check-square' />
      </span>
  ) : (
    <div>
      <Typography.Link disabled={editingKey !== ''} onClick={() => editOrder(record)}>
        <img className='edit-img' src={editIcon} alt='edit' />
      </Typography.Link>
      {
        (hasAnyRole([Roles.Admin])) && (
          <Popconfirm
            okButtonProps={{
              style: {
                padding: '0.8rem',
              },
            }}
            cancelButtonProps={{
              style: {
                padding: '0.8rem',
              },
            }}
            okText={<Typography.Title style={{ color: 'white' }}
                                      level={5}>{t('ordersImportPage.table.ok')}</Typography.Title>}
            cancelText={<Typography.Title level={5}>{t('ordersImportPage.table.cancel')}</Typography.Title>}
            title={<Typography.Title level={4}>{t('ordersImportPage.table.confirmDelete')}</Typography.Title>}
            onConfirm={() => deleteOrder(record)}
          >
            <img className='edit-img' src={closeIcon} alt='edit' />
          </Popconfirm>
        )
      }

    </div>
  );
};

interface OperationsColumnProps {
  record: ImportTableOrder,
  isEditing: (record: ImportTableOrder) => boolean,
  validationStatus: Record<string, boolean>,
  save: (key: React.Key) => void,
  cancel: () => void,
  editingKey: string,
  hasAnyRole: (roles: string[]) => boolean,
  editOrder: (record: Partial<ImportTableOrder> & { key: React.Key }) => void,
  deleteOrder: (record: Partial<ImportTableOrder> & { key: React.Key }) => void
}

export default OperationsColumn;