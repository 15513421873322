import React, { FC, ReactNode } from 'react';

import s from './index.module.scss';

import Text, { AntTextProps } from '@/components/controls/text/antText';

const AntLabel: FC<AntLabelProps> = ({ required = false, children, ...props }) => {
  return (
    <Text className={s['custom-ant-label']} weight={500} color={'contentDopDark'} {...props}>
      {children} {required && <span className={s['required-input-label']}>*</span>}
    </Text>
  );
};

interface AntLabelProps extends AntTextProps{
  required?: boolean;
  children?: ReactNode | ReactNode[];
}

export default AntLabel;