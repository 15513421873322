import React, { CSSProperties, FC, ReactNode } from 'react';
import { Typography } from 'antd';

import {
  textColors,
  textColorVariants,
  textSizes,
  textSizeVariants,
  textWeights,
  textWeightVariants,
} from '@/common/utils/utilTypes';

const { Paragraph } = Typography


const AntParagraph: FC<AntParagraphProps> = ({ color = '', weight = '', size = '', style = {}, children, ...props }) => {
  return (
    <Paragraph
      style={{
        color: textColors[color] || color || textColors.contentDopDark,
        fontWeight: textWeights[weight] || weight || textWeights.regular,
        fontSize: textSizes[size] || size || textSizes.body,
        padding: 0,
        margin: 0,
        ...style,
      }}
      {...props}
    >
      {children}
    </Paragraph>
  );
};

interface AntParagraphProps {
  color?: keyof typeof textColorVariants | string,
  weight?: keyof typeof textWeightVariants | string,
  size?: keyof typeof textSizeVariants | string,
  style?: CSSProperties,
  children?: ReactNode[]
}

export default AntParagraph;