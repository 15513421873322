export const couriersPage = {
  createCourierAccount: "Создание аккаунта для курьера",
  city: "Город",
  firstName: "Имя",
  enter_firstName: "Введите имя",
  lastName: "Фамилия",
  enter_lastName: "Введите фамилию",
  middleName: "Отчество",
  enter_middleName: "Введите Отчество",
  phoneNumber: "Номер телефона",
  iin: "ИИН",
  enter_iin: "Введите ИИН",
  transport: "Наличие транспорта",
  enter_transport: "Укажите наличие транспорта",
  has: "Есть",
  absent: "Отсутствует",
  clear: "Очистить",
  courierAddress: "Адрес проживания",
  enter_courierAddress: "Введите адрес проживания",
  contractNumber: "Номер контракта",
  enter_contractNumber: "Введите номер контракта",
  contractStartDate: "Дата начала контракта",
  enter_contractStartDate: {
    start: "Нажмите чтобы ввести",
    end: "дату начала"
  },
  contractEndDate: "Дата окончания контракта",
  enter_contractEndDate: {
    start: "Нажмите чтобы ввести",
    end: "дату окончания"
  },
  birthdate: "Дата окончания контракта",
  enter_birthdate: {
    start: "Нажмите чтобы ввести",
    end: "день рождения"
  },
  comments: "Комментарии",
  enter_comments: "Напишите комментарии",
  createCourier: "Создать курьера",
  saveChanges: "Сохранить изменения",
  edit: "Редактировать",
  deleteCourier: "Удалить курьера",
  cancelEdit: "Отменить редактирование",
  validation: {
    tooLong: "Слишком длинный {{field}}",
    name: "Неверный формат имени",
    lastName: "Неверный формат фамилии",
    middleName: "Неверный формат отчества",
    phone: "Укажите ваш номер телефона",
    phoneFormat: "Формат номера: +7 XXX XXX-XX-XX",
    iinFormat: "ИИН состоит из 12 цифр",
    incorrectFormat: "Неправильный формат",
    cityRequired: "Город обязателен"
  }
}