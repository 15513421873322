import React, { FC, useState } from 'react';
import { Table, TableColumnsType } from 'antd';

import s from '../index.module.scss';

import { handbookClientItem, handbookTableClient } from '@/common/models/handbook';
import { useTranslation } from 'react-i18next';
import { checkIfEmpty, formatNumberMasked, formatToTimeStamp } from '@/common/utils/utils';
import Text from '@/components/controls/text/antText';
import { formatDate } from 'date-fns';
import { Formats } from '@/common/constants/dateFormat';
import { ru } from 'date-fns/locale';

const ClientHandbookTable: FC<ClientHandbookTableProps> = ({ clients, isLoading }) => {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState({
    curr: 1,
    size: 20,
  });

  const columns: TableColumnsType<handbookTableClient> = [
    {
      title: (
        <Text weight={'medium'} size={'12px'} color={'contentMain'}>
          {t('clientHandbookPage.columns.name')}
        </Text>
      ),
      dataIndex: 'name',
      width: '15.8%',
      className: s['client-table-td'],
      sorter: (a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }),
      render: (_: any, record: handbookTableClient) => {
        return <Text weight={'regular'} size={'small'} color={'contentMain'}>
          {record?.name}
        </Text>;
      },
    },
    {
      title: (
        <Text weight={'medium'} size={'12px'} color={'contentMain'}>
          {t('clientHandbookPage.columns.bin')}
        </Text>
      ),
      dataIndex: 'bin',
      width: '10%',
      className: s['client-table-td'],
      render: (_: any, record: handbookTableClient) => {
        return <Text weight={'regular'} size={'small'} color={'contentMain'}>
          {record?.bin}
        </Text>;
      },

    },
    {
      title: (
        <Text weight={'medium'} size={'12px'} color={'contentMain'}>
          {t('clientHandbookPage.columns.clientUsername')}
        </Text>
      ),
      dataIndex: 'clientUsername',
      width: '13.1%',
      className: s['client-table-td'],
      sorter: (a, b) => a.clientUsername.localeCompare(b.clientUsername, undefined, { sensitivity: 'base' }),
    },
    {
      title: (
        <Text weight={'medium'} size={'12px'} color={'contentMain'}>
          {t('clientHandbookPage.columns.contactPhone')}
        </Text>
      ),
      dataIndex: 'contactPhone',
      width: '11.75%',
      render: (_: any, record: handbookTableClient) => {
        return <Text weight={'regular'} size={'small'} color={'contentMain'}>
          {formatNumberMasked(record?.contactPhone) || record?.contactPhone}
        </Text>;
      },
    },
    {
      title: (
        <Text weight={'medium'} size={'12px'} color={'contentMain'}>
          {t('clientHandbookPage.columns.contactName')}
        </Text>
      ),
      dataIndex: 'contactName',
      width: '12%',
      className: s['client-table-td'],
    },
    {
      title: (
        <Text weight={'medium'} size={'12px'} color={'contentMain'}>
          {t('clientHandbookPage.columns.addressString')}
        </Text>
      ),
      dataIndex: 'addressString',
      width: '15.36%',
      className: s['client-table-td'],
    },
    {
      title: (
        <Text weight={'medium'} size={'12px'} color={'contentMain'}>
          {t('clientHandbookPage.columns.createDate')}
        </Text>
      ),
      dataIndex: 'createDate',
      width: '10.39%',
      className: s['client-table-td'],
      sorter: {
        compare: (a, b) => new Date(a.createDate).getTime() - new Date(b.createDate).getTime(),
      },
      render: (_: any, record: handbookTableClient) => {
        return <Text weight={'regular'} size={'small'} color={'contentMain'}>
          {
            record?.createDate ? (
              formatDate(formatToTimeStamp(record?.createDate), Formats.DATE_DDMMMYYYY_TIME, { locale: ru })
            ) : ''
          }
        </Text>;
      },

    },
    {
      title: (
        <Text weight={'medium'} size={'12px'} color={'contentMain'}>
          {t('clientHandbookPage.columns.lastOrderCreateDate')}
        </Text>
      ),
      dataIndex: 'lastOrderCreateDate',
      width: '11.65%',
      className: s['client-table-td'],
      sorter: {
        compare: (a, b) => new Date(a.lastOrderCreateDate).getTime() - new Date(b.lastOrderCreateDate).getTime(),
      },
      render: (_: any, record: handbookTableClient) => {
        return <Text weight={'regular'} size={'small'} color={'contentMain'}>
          {
            record?.lastOrderCreateDate ? (
              formatDate(formatToTimeStamp(record?.lastOrderCreateDate), Formats.DATE_DDMMMYYYY_TIME, { locale: ru })
            ) : ''
          }

        </Text>;
      },

    },
  ];

  const tableData = clients?.map(client => {
    const { clientUsernames, id, companyId, ...rest } = client;
    return {
      ...rest,
      clientUsername: checkIfEmpty(clientUsernames[0]),
    };
  }) || [];

  const handlePageChange = (page, pageSize) => {
    setPagination(prev => ({
      ...prev,
      curr: page,
      size: pageSize,
    }));
  };

  return (

    <Table
      columns={columns}
      dataSource={tableData}
      className={s['custom-client-container']}
      loading={isLoading}
      components={{
        table: (props) =>
          <div className={s['custom-client-table-wrapper']}>
            <table {...props} className={s['custom-client-table']} />
          </div>,
        body: {
          cell: (props) => {
            return <DefaultClientCell className={s['client-table-cell']} {...props} />;
          },
        },
      }}
      pagination={{
        current: pagination.curr,
        pageSize: pagination.size,
        total: tableData.length,
        locale: {
          items_per_page: ' заказов',
          jump_to: 'Перейти к',
          jump_to_confirm: 'Confirm',
          page: 'странице', // Remove or change the word "Page"
          prev_page: 'Previous',
          next_page: 'Next',
        },
        showSizeChanger: true,
        position: ['bottomLeft'],
        pageSizeOptions: ['5', '10', '20', '50'],
        showQuickJumper: true,
        onChange: handlePageChange,
      }}

    >
    </Table>
  );
};

const DefaultClientCell = ({ children, record, form, ...restProps }) => {
  return <td {...restProps}>{children}</td>;
};


interface ClientHandbookTableProps {
  clients: handbookClientItem,
  isLoading: boolean
}

export default ClientHandbookTable;