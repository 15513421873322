import React from 'react';

import s from './index.module.scss'
import clsx from 'clsx';

const AntHelp = ({children, className = ''}) => {
  return (
    <span className={clsx(s['custom-ant-help'], className)}>{children}</span>
  );
};

export default AntHelp;