import React from 'react'
import { Spinner } from 'react-bootstrap'
import { CupHotFill, DatabaseExclamation } from 'react-bootstrap-icons'

import { OrderItem } from '@/common/models/order'
import OrdersContentTable from './OrdersContentTable'
import {Roles} from "@/common/constants/roles";

const OrdersView = ({ data, isLoading, error, role = Roles.Client, onAnyChange = () => {}, refetch }: OrdersViewProps) => {

    if (isLoading) {
        return (
            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                <Spinner />
                <span className="heading-2 my-3">Получение заказов...</span>
            </div>
        )
    }

    if (error) {
        return (
            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                <div className="d-flex flex-column align-items-center">
                    <span className="text-danger heading-2 mb-2">
                        <DatabaseExclamation />
                    </span>
                    <span className="heading-2 text-danger">Упс... что-то пошло не так</span>
                </div>
                <span className="text-danger my-3">{error.message}</span>
            </div>
        )
    }

    if (!data || (data && data.length < 1)) {
        return (
            <div className="border-dotted border-rounded-2 d-flex justify-content-center align-items-center h-100">
                <div className="d-flex flex-column align-items-center">
                    <span className="text-muted heading-2 mb-2">
                        <CupHotFill />
                    </span>
                    <span className="heading-2 text-muted">Нет заказов</span>
                </div>
            </div>
        )
    }

    return (
        <OrdersContentTable
            data={data}
            expanded={role === Roles.Manager}
            onAnyChange={onAnyChange}
            refetch={refetch}
        />
    )
}

interface OrdersViewProps {
    data: OrderItem[],
    isLoading: boolean,
    error?: any,
    role?: string,
    onAnyChange: () => void,
    refetch: () => void,
}

export default OrdersView
