import React, { CSSProperties, memo, useCallback, useRef, useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate'
import { OptionProps, components } from 'react-select';
import { useTranslation } from 'react-i18next';

import { CityService } from '@/common/api/CityService'
import { SelectOption } from '@/common/models/util'
import { useMutation } from 'react-query';
import { City } from '@/common/models/address';

export const customSelectStyles = {
    input: (provided, meta) => ({
        ...provided,
        ...meta.selectProps.customStyles,
        fontSize: "1rem"
    }),
    control: (provided, meta) => ({
        ...provided,
        ...meta.selectProps.customStyles,
        width: '100%',
        padding: "0.1rem",
        borderRadius: "0.5rem"
    }),
    group: (provided) => ({
        ...provided,
        padding: 0
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: 0
    })
};


const CityAsyncSelect = ({
                             customStyles = {}, onChange = () => {}, name, defaultValue,
                             fetchCities = () => {},
                             isLoading = false, readOnly = false, addableOptions = false }: CityAsyncSelectProps) => {
    const hasMount = useRef(false)
    const [city, setCity] = useState(defaultValue?.value ? defaultValue : null)


    const { t } = useTranslation()
    if(hasMount.current === false){
        setCity(defaultValue)
        hasMount.current = true
    }
    const buildSelectOption = item => ({
        value: item?.id,
        label: item?.name,
    })
    const additionalOptions = useCallback((search) => {
        if (!addableOptions) return [];
        return [
            {
                value: 'hint',
                label: t('ordersImportPage.table.cityError'),
                isError: true,
                isHint: true,
                isDisabled: true,
            },
            {
                value: search,
                label: t('ordersImportPage.table.newCity'),
                isHint: true,
                isError: false,
                isDisabled: false,
            },
        ];
    }, [addableOptions, t]);

    const loadOptions = async (search: string, _loadedOptions: any, { page }: any) => {
        const response = await CityService.getAll({ name: search, page });

        const options = response.cities.map(buildSelectOption);

        if (!response.isLast) {
            return {
                options,
                hasMore: true,
                additional: { page: page + 1 },
            };
        }

        return {
            options: [
                ...options,
                ...(addableOptions ? additionalOptions(search) : []),
            ],
            hasMore: false,
            additional: { page: page + 1 },
        };
    };

    const { mutateAsync: createCity } = useMutation(['createCity'],(name) => CityService.createCity(name))

    const onOptionSelect = async (option: SelectOption) => {
        try {
            if(option?.isHint && !option?.isDisabled) {
                const newCity: City = await createCity(option?.value.trim())
                fetchCities()
                setCity({
                    value: newCity?.id,
                    label: newCity?.name
                })
                onChange({
                    value: newCity?.id,
                    label: newCity?.name
                })
            } else {
                setCity(option)
                onChange(option)
            }

        } catch (e) {
            setCity({
                value: "",
                label: ""
            })
            onChange({
                value: "",
                label: ""
            })
        }

    }

return (
  <>
      <AsyncPaginate
        customStyles={customStyles}
        defaultValue={defaultValue}
        placeholder={t('ordersImportPage.table.emptyCity')}
        value={city}
        components={{Option: CustomOption}}
        blurInputOnSelect={true}
        defaultOptions
        styles={customSelectStyles}
        name={`city-${name}`}
        isDisabled={readOnly}
        isLoading={isLoading}
        isSearchable={true}
        loadOptions={loadOptions}
        onChange={onOptionSelect}
        noOptionsMessage={() => t('api.messages.loading')}
        additional={{
            page: 0,
        }}
      />
  </>

)
}

const CustomOption: React.FC<CustomOptionProps> = (props) => {
    return (
      <components.Option {...props}>
          {props.data.isHint ? (
            props.data.isError ? (
              <div className={"text-muted"}>{props.data.label}</div>
            ) : (
              <div className={"text-primary"}>{props.data.label}</div>
            )
          ) : (
            props.children
          )}
      </components.Option>
    );
};

interface CustomOptionProps extends OptionProps<any, any> {

    data: {
        label: string;
        value: any;
        [key: string]: any;
    };
}

interface CityAsyncSelectProps {
    customStyles?: CSSProperties,
    onChange: (option: SelectOption) => void,
    name?: string,
    fetchCities: () => void,
    defaultValue?: SelectOption,
    isLoading?: boolean,
    readOnly?: boolean,
    error?: any,
    addableOptions?: boolean
}

export default memo(CityAsyncSelect)