export const authPage = {
  login: 'Логин',
  password: 'Пароль',
  signIn: 'Войти',
  noAcc: 'Еще нет аккаунта?',
  error: {
    incorrectLogin: 'Неверный логин',
    incorrectPassword: 'Неверный пароль',
    emptyLogin: 'Введите логин',
    emptyPassword: 'Введите пароль',
  },
  placeholder: {
    login: 'Введите ваш логин',
    password: 'Введите ваш пароль',
  },
  authorization: 'Авторизация',
  enterData: 'Введите свои данные для входа'
}