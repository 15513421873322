import React, { CSSProperties, FC, ReactNode } from 'react';
import { Typography } from 'antd';
import clsx from 'clsx';

import s from './index.module.scss'

import {
  headingSizeVariants,
  textColors,
  textColorVariants,
} from '@/common/utils/utilTypes';

const AntHeading: FC<AntTextProps> = ({ color, className = '',size = '', style = {}, children, ...props }) => {
  return (
    <div className={s['custom-ant-heading-wrapper']}>
      <Typography.Title
        level={headingSizeVariants[size]}
        className={clsx(
          className,
          s[`custom-ant-heading`],
          s[`custom-${size.toString()}-heading`]
          )}
        style={{
          ...(color ? { color: textColors[color] || color } : {}),
          ...style,
        }}
        {...props}
      >
        {children}
      </Typography.Title>
    </div>
  );
};

type headingLevels = keyof typeof headingSizeVariants;

interface AntTextProps {
  color?: keyof typeof textColorVariants | string,
  size?: headingLevels | string,
  style?: CSSProperties,
  className?: string,
  children?: ReactNode | ReactNode[],
}


export default AntHeading;