import {Roles} from "@/common/constants/roles";

// TODO: temporary solution. Required to fix back responses for commented options.
export const searchOptions = {
    [Roles.Manager]: [
        {
            value: 'orderItemId',
        },
        {
            value: 'orderNumber',
        },
        {
            value: 'deliverySenderName',
        },
        {
            value: 'deliverySenderBin',
        },
        {
            value: 'deliveryReceiverName',
        },
        {
            value: 'deliveryReceiverBin',
        },
        {
            value: 'courier',
        },
        {
            value: 'cell',
        },
        {
            value: 'zone',
        },
    ],
    [Roles.Admin]: [
    	{
        	value: 'orderItemId',
        },
        {
        	value: 'courier',
        },
        {
        	value: 'cell',
        },
        {
        	value: 'zone',
        },
    ],
    [Roles.Client]: [
        {
            value: 'orderItemId',
        },
        {
            value: 'deliveryReceiverName',
        },
        {
            value: 'deliveryReceiverBin',
        },
    ]
}

export const createDateOptions = [
    {
        value: '*',
        text: 'forAll',
    },
    {
        value: 'today',
        text: 'forToday',
    },
    {
        value: 'weekly',
        text: 'forWeek',
    },
    {
        value: 'monthly',
        text: 'forMonth',
    },
    {
        value: 'yearly',
        text: 'forYear',
    },
    {
        value: 'custom',
        text: 'customRange',
    },
]

export const deliveryProviderTypeOptions = [
    {
        value: '*',
        text: 'Типы доставки',
    },
    {
        value: 'IN_CITY',
        text: 'ordersPage.providerTypes.inCity',
    },
    {
        value: 'INTER_CITY',
        text: 'ordersPage.providerTypes.crossCity',
    },
    {
        value: 'ALSECO',
        text: 'ordersPage.providerTypes.alseco',
    },
    {
        value: 'THIRD_PARTY_PROVIDER',
        text: 'ordersPage.providerTypes.qazPost',
    },
]

export const deliveryTypeOptions = [
    {
        value: '*',
        text: 'Типы доставки',
    },
    {
        value: 'STANDARD',
        text: 'Обычный',
    },
    {
        value: 'URGENT',
        text: 'Срочный',
    }
];


        export const parcelTypeOptions = [
    {
        value: '*',
        text: 'Типы посылки', 
    },
    {
        value: 'ENVELOPE',
        text: 'Корреспонденция', 
    },
    {
        value: 'SMALL_PACKAGE',
        text: 'Мелкие', 
    },
    {
        value: 'LARGE_PACKAGE',
        text: 'Крупногабаритные', 
    },
]

export const deliveryStatusOptions = [
    {
        value: '*',
        text: 'Все статусы',
        query: 'NEW,READY_FOR_PICKUP,PICKUP_COURIER_ASSIGNED,ON_WAY_TO_WAREHOUSE,DELIVERED_TO_WAREHOUSE,ACCEPTED_BY_WAREHOUSE,PENDING_CLIENT_CLARIFICATION,MARKING_AT_WAREHOUSE,MARKED_AT_WAREHOUSE,SORTING_AT_WAREHOUSE,SORTED_AT_WAREHOUSE,READY_FOR_DELIVERY,DELIVERY_COURIER_ASSIGNED,DELIVERY_IN_PROGRESS,DELIVERED_BY_COURIER,READY_FOR_DEPARTURE_DELIVERY,DELIVERY_TO_DEPARTURE,DELIVERY_IN_TRANSIT,READY_FOR_ARRIVAL_DELIVERY,PICKUP_COURIER_ARRIVAL_ASSIGNED,DELIVERY_COURIER_ARRIVAL_ASSIGNED'
    },
    {
        value: 'NEW',
        text: 'Новые',
        query: 'NEW'
    },
    {
        value: 'IN_PROGRESS',
        text: 'В обработке',
        query: 'READY_FOR_PICKUP,DELIVERY_IN_PROGRESS'
    },
    {
        value: 'DELIVERED',
        text: 'Доставлены',
        query: 'DELIVERED_ACCEPTED_MANAGER'
    },
    {
        value: 'NON_DELIVERED',
        text: 'Не доставлены',
        query: 'DELIVERY_DESTROYED,DELIVERY_RETURNED_TO_SENDER'

    },
]


export const fields = {
    senderName: "senderName",
    senderAddress: "senderAddress",
    senderAddressComments: "senderAddressComments",
    senderPhone: "senderPhone",
    senderContactName: "senderContactName",
    senderDeliveryType: "senderDeliveryType",
    senderParcelType: "senderParcelType",
    receiverName: "receiverName",
    receiverAddress: "receiverAddress",
    receiverAddressComments: "receiverAddressComments",
    receiverPhone: "receiverPhone",
    receiverContactName: "receiverContactName",
    receiverOrderComments: "receiverOrderComments",
}

export const RouteCellOptions = {
    "DZN": "Б17",
    "PLX": "Б13",
    "KZO": "Б10",
    "AKX": "Б7",
    "CIT": "Б12",
    "DMB": "Б11",
    "GUW": "Б4",
    "KGF": "Б15",
    "KOV": "Б3",
    "KSN": "Б8",
    "PWQ": "Б2",
    "SCO": "Б5",
    "TDK": "Б17",
    "UKK": "Б14",
    "URA": "Б6",
    "РРК": "Б1",
    "ЭКИ": "Б17",
    "ALM-1": "А1",
    "ALM-2": "А2",
    "ALM-3": "А3",
    "ALM-4": "А4",
    "ALM-5": "А5",
    "ALM-6": "А6",
    "ALM-7": "А7",
    "ALM-8": "А8",
    "ALM-9": "А9",
    "ALM-10": "А10",
    "ALM-11": "А11",
    "ALM-12": "А12",
    "ALM-13": "А13",
    "ALM-14": "А14",
    "ALM-15": "А15",
    "ALM-16": "А16",
    "ALM-17": "А17",
    "ALM-18": "А18",
    "ALM-19": "А19",
    "ALM-20": "А20",
    "NQZ-1": "В1",
    "NQZ-2": "В2",
    "NQZ-3": "В3",
    "NQZ-4": "В4",
    "NQZ-5": "В5",
    "NQZ-6": "В6",
    "NQZ-7": "В7",
    "NQZ-8": "В8",
    "NQZ-9": "В9",
    "NQZ-10": "В10",
    "NQZ-11": "В11",
    "NQZ-12": "В12",
    "NQZ-13": "В13",
}

export const cellOptions = [
    "А1",
    "А2",
    "А3",
    "А4",
    "А5",
    "А6",
    "А7",
    "А8",
    "А9",
    "А10",
    "А11",
    "А12",
    "А13",
    "А14",
    "А15",
    "А16",
    "А17",
    "А18",
    "А19",
    "А20",
    "А21",
    "А24",
    "Б1",
    "Б2",
    "Б3",
    "Б4",
    "Б5",
    "Б6",
    "Б7",
    "Б8",
    "Б9",
    "Б10",
    "Б11",
    "Б12",
    "Б13",
    "Б14",
    "Б15",
    "Б17",
    "В1",
    "В2",
    "В3",
    "В4",
    "В5",
    "В6",
    "В7",
    "В8",
    "В9",
    "В10",
    "В11",
    "В12",
    "В13"
]


export const clientAccess = new Map()
clientAccess.set("senderName", true)
clientAccess.set("senderAddress", true)
clientAccess.set("senderAddressComments", true)
clientAccess.set("senderPhone", true)
clientAccess.set("senderContactName", true)
clientAccess.set("senderDeliveryType", true)
clientAccess.set("senderParcelType", true)
clientAccess.set("receiverName", true)
clientAccess.set("receiverAddress", true)
clientAccess.set("receiverAddressComments", true)
clientAccess.set("receiverPhone", true)
clientAccess.set("receiverContactName", true)
clientAccess.set("receiverOrderComments", true)


export const sortFieldOptions = {
    "createDate": "Дате создания",
    "courier": "Курьерам",
    "zone": "Маршрутам",
    "cell": "Ячейкам",
    "estimatedDeliveryDate": "Дате доставки",
    "status": "Статусу",
}