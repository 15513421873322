export const clientHandbookPage = {
  pageTitle: 'Справочник клиентов',
  columns: {
    createDate: 'Регистрация',
    name: 'Компания',
    bin: 'БИН',
    contactPhone: 'Номер телефона',
    contactName: 'Контактное лицо',
    addressString: 'Адрес',
    lastOrderCreateDate: 'Последний заказ',
    clientUsername: 'Логин',
  }
}