import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { ImportTableOrder, OrderItem } from '@/common/models/order';
import useImport from '@/common/hooks/useImport';
import {
  changeAddress,
  getReadyToImportOrders,
  hasEmptyFields,
} from '@/common/utils/parsers';
import OrderImportConfirmModal from '@/pages/order-import/components/modals/OrderImportConfirmModal';
import OrderService from '@/common/api/OrderService';
import useAuth from '@/common/hooks/useAuth';
import EditableCell from '@/pages/order-import/components/EditableCell';
import { getColumns, getRowClassName, getSortedTableOrders } from '@/pages/order-import/importTypesOptions';
import { City } from '@common/models/address';

export interface TableOrder {
  key: string,
  id: string,
  createDate: string,
  Sender: string,
  Receiver: string,
  total: number,
  phone: string,
  city: string,
  addressString: string,
  addressFromFile: string,
  addressComments: string,
  isFixed?: boolean,
}

const isRowValid = (row: ImportTableOrder) => {
  return !!(row.addressString && row.city && row.phone);
};


const OrderImportTable: React.FC<OrderImportTableProps> = observer(({cities, fetchCities}) => {
  const hasMount = useRef(false)
  const {t} = useTranslation()
  const { hasAnyRole } = useAuth()
  const [form] = Form.useForm();
  const navigate = useNavigate()
  const {importForm, setImportForm} = useImport()
  const [validationStatus, setValidationStatus] = useState<Record<string, boolean>>({});
  const [stateFullFields, setStateFullFields] = useState({
    city: null,
    address: null
  })

  const tableOrders: OrderItem[] = getSortedTableOrders(importForm, cities, isRowValid)
  useEffect(() => {
    hasMount.current = true
  }, [])
  useEffect(() => {
    if(hasMount?.current){
      const brokenOrders = tableOrders?.filter(order => hasEmptyFields(order));
      if (importForm?.brokenOrders?.length !== brokenOrders?.length) {
        setImportForm({
          ...importForm,
          brokenOrders,
        });
      }
    }
  }, [cities, importForm, setImportForm, tableOrders]);

  const updateValidationStatus = (key: string) => {
    const row = form.getFieldsValue();
    const isValid = Object.keys(row).every((field) => field === 'phone' ? true : row[field]); // Check if all fields are filled except phone
    setValidationStatus((prev) => ({
      ...prev,
      [key]: isValid,
    }));
  };
  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record: ImportTableOrder) => record.key === editingKey;

  const editOrder = (record: Partial<ImportTableOrder> & { key: React.Key }) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.key);
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as ImportTableOrder;
      const index = tableOrders.findIndex((item) => key === item.key);
      if (index > -1) {
        changeAddress({
          address: stateFullFields?.address || importForm?.orders?.find(order => order?.id === key)?.deliveryReceiver?.address,
          id: key,
          phone: row?.phone,
          comments: row?.addressComments,
          defaultOrders: importForm?.orders,
          setDefaultOrders: (updatedOrders)=>{
            setImportForm({
              ...importForm,
              orders: updatedOrders,
            });
          },
          cities: cities
        });

        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const cancel = () => {
    setEditingKey('');
  };

  const deleteOrder = (record: Partial<ImportTableOrder> & { key: React.Key }) => {
    setImportForm({
      ...importForm,
      orders: importForm?.orders?.filter((order: OrderItem) => order?.id !== record?.id)
    })
  };

  const mergedColumns = getColumns({
    form, cities, isEditing, validationStatus, save, cancel, editingKey, editOrder,
    hasAnyRole, deleteOrder, updateValidationStatus, setStateFullFields, fetchCities
  });
  const [confirmModal, setConfirmModal] = useState({
    title: '',
    show: false,
    handleSubmit: async () => {},
    handleClose: () => {}
  })

  function handleNextStep () {
    setImportForm({
      ...importForm,
      step: importForm.step + 1
    })
  }

  return (
    <Form form={form} component={false}>
      <Table<ImportTableOrder>
        components={{
          body: {
            cell: (props) => {
              return props.editing ? (
                <EditableCell {...props} />
              ) : (
                <DefaultCell {...props} />
              );
            },
          },
        }}
        bordered
        dataSource={tableOrders}
        columns={mergedColumns}
        rowClassName={record => `import-row ${getRowClassName(record, cities)}`}
        pagination={{ onChange: cancel, position: ['bottomLeft'] }}
      />
      <div className='d-flex justify-content-between' style={{padding: '0.8rem 0'}}>
        <Button
          style={{fontSize: '0.85rem', padding: '1.25rem 1.5rem'}}
          color={'danger'}
          onClick={() => setConfirmModal({
            title: t('ordersImportPage.cancel'),
            show: true,
            handleSubmit: async () => {
              setImportForm({
                step: 0,
                type: "",
                fileName: "",
                fileSize: '',
                orders: [],
                brokenOrders: [],
                initialOrders: []
              })
              navigate('/orders')
            },
            handleClose: () => {
              setConfirmModal(prev => ({
                ...prev,
                show: false
              }))
            }
          })}
        >{t('ordersImportPage.buttons.cancel')}</Button>
        <Button
          disabled={importForm?.brokenOrders?.length > 0}
          style={{fontSize: '0.85rem', padding: '1.25rem 1.5rem'}}
          type={'primary'}
          onClick={() => setConfirmModal({
            title: t('ordersImportPage.confirm'),
            show: true,
            handleSubmit: async () => {
              const createdOrders = await getReadyToImportOrders(importForm?.type, tableOrders, importForm?.orders, cities)
              if(importForm?.type === 'Alseko') {
                await OrderService.multipleChangeStatus(createdOrders?.orderItems?.map(order => order?.id), 'WAREHOUSE_FINISH_SORTING')
              }
              else {
                await OrderService.multipleChangeStatus(createdOrders?.orderItems?.map(order => order?.id), 'CONFIRM_NEW_ORDER')
              }
              return createdOrders
            },
            handleClose: () => {
              setConfirmModal(prev => ({
                ...prev,
                show: false
              }))
            }
          })}
        >{t('ordersImportPage.buttons.create')}</Button>
      </div>
      {confirmModal?.show && (
        <OrderImportConfirmModal key={confirmModal} title={confirmModal?.title} handleClose={confirmModal?.handleClose} onSubmit={confirmModal?.handleSubmit} handleNextStep={handleNextStep}/>
        )}
    </Form>
  );
});

interface OrderImportTableProps {
  cities: City[]
  fetchCities: () => void
}

const DefaultCell = ({ children, record, form, ...restProps }) => {
  return <td {...restProps}>{children}</td>;
};



export default OrderImportTable;
