import React, { CSSProperties, FC } from 'react';
import { Spinner } from 'react-bootstrap';
import DefaultButton from '@/components/buttons/DefaultButton';

interface ConfirmModalProps {
  onSuccess: () => {},
  onSuccessText?: string,
  onCancel: () => {},
  onCancelText?: string,
  children: React.ReactNode,
  isLoading?: boolean,
  className?: string,
  styles?: CSSProperties
}

const ConfirmModal: FC<ConfirmModalProps> = ({onCancel, onCancelText = "Нет", onSuccessText = "Да", onSuccess, isLoading = false, className = '', styles = {}, children}) => {
  return (
    <div className={`position-absolute d-flex justify-content-center ${className}`} style={styles}>
      <div style={{border: "2px solid #b7b7b7", backgroundColor: "rgb(249,249,249)"}} className={"d-flex flex-column align-items-center p-2 border-opacity-10 border-rounded-1 bg-opacity-10"}>
        <p className="heading-5 my-0 ">{children}</p>
        <div className="d-flex justify-content-around my-1">
          {isLoading ? (<Spinner/>) : (
            <>
              <DefaultButton className={"text-light"} variant={"danger"} onClick={onCancel}>{onCancelText}</DefaultButton>
              <DefaultButton variant={"primary"} className={"mx-2"} onClick={onSuccess}>{onSuccessText}</DefaultButton>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;