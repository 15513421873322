import React from 'react';

import { DeliveryPartyCreateData } from '@/common/models/deliveryParty';
import { ImportTableOrder, OrderItem } from '@/common/models/order';
import { formatDate } from 'date-fns';
import { formatToTimeStamp } from '@/common/utils/utils';
import { Formats } from '@/common/constants/dateFormat';
import { ru } from 'date-fns/locale';
import { TableOrder } from '@/pages/order-import/components/OrderImportTable';
import OperationsColumn from '@/pages/order-import/components/OperationsColumn';
import { t } from 'i18next';
import { hasEmptyFields } from '@/common/utils/parsers';

export enum importTypes {
  'HH', 'Alseko', 'Qazpost'
}

export const importTypesOptions = [
  { value: 'HH', label: 'deliveryProcessTypes.HH' },
  { value: 'Alseko', label: 'deliveryProcessTypes.Alseko' },
  { value: 'Qazpost', label: 'deliveryProcessTypes.Qazpost' },
  { value: 'CafeSoft', label: 'deliveryProcessTypes.CafeSoft' }
];

export const TypeExtensionMap = {
  'Alseko': {
    label: ".pdf",
    value: "application/pdf"
  },
  'HH': {
    label: ".xls, .xlsx",
    value: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  },
  'Qazpost': {
    label: '.xls, .xlsx',
    value: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  },
  'CafeSoft': {
    label: '.xls, .xlsx',
    value: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  }
}

export const TypeSenderMap: DeliveryPartyCreateData = {
  'HH': {
    address: {
      city: {
        id: 2,
        name: t('cities.Almaty')
      },
      street: "",
      building: "",
      apartment: "",
      comment: "",
    },

    contactName: "ТОО HeadHunter",
    isCompany: true,
    phone: "",
    company: {
      name: "ТОО HeadHunter",
      bin: ""
    },
  },
  'Alseko': {
    phone: "",
    contactName: "Alseko",
    isCompany: true,
    company: {
      name: "Alseko",
      bin: ""
    },
    address: {
      city: {
        id: 2,
        name: t('cities.Almaty')
      },
      street: "",
      building: "",
      apartment: "",
      comment: "",
    },
  },
  'CafeSoft': {
    phone: "",
    contactName: "CafeSoft",
    isCompany: true,
    company: {
      name: "CafeSoft",
      bin: ""
    },
    address: {
      city: {
        id: 2,
        name: t('cities.Almaty')
      },
      street: "",
      building: "",
      apartment: "",
      comment: "",
    },
  },
}

export const getColumns = ({
                             form,
                             cities,
                             isEditing,
                             validationStatus,
                             save,
                             cancel,
                             editingKey,
                             editOrder,
                             hasAnyRole,
                             deleteOrder,
                             updateValidationStatus,
                             setStateFullFields,
                             fetchCities
                           }) => {
  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      width: 30,
      sorter: {
        compare: (a, b) => a.id - b.id,
      },
    },
    {
      title: t('ordersImportPage.table.columns.createDate'),
      dataIndex: 'createDate',
      width: 120,
      sorter: {
        compare: (a, b) => new Date(a.createDate).getTime() - new Date(b.createDate).getTime(),
        multiple: 3,
      },
      render: (_: any, record: ImportTableOrder) => {
        return <td className={'import-cell'}>
          {formatDate(formatToTimeStamp(record?.createDate), Formats.DATE_DMMMY_TIME, {locale: ru})}
        </td>;
      }
    },
    {
      title: t('ordersImportPage.table.columns.Sender'),
      dataIndex: 'Sender',
      render: (_: any, record: ImportTableOrder) => {
        return <td width={100} className={'import-cell'}>
          {record?.Sender}
        </td>;
      }
    },
    {
      title: t('ordersImportPage.table.columns.Receiver'),
      dataIndex: 'Receiver',
      width: 250,
      editable: true,
      render: (_: any, record: ImportTableOrder) => {
        return <td className={'import-cell'}>
          {record?.Receiver}
        </td>;
      }
    },
    {
      title: t('ordersImportPage.table.columns.city'),
      dataIndex: 'city',
      editable: true,
      width: 250,
      render: (_: any, record: ImportTableOrder) => {
        const currCity = cities?.find(city => city?.name === record?.city);
        return <td className={'import-cell'}>
          {currCity?.name}
        </td>;
      },
    },
    {
      title: t('ordersImportPage.table.columns.addressString'),
      dataIndex: 'addressString',
      width: 250,
      editable: true,
      render: (_: any, record: ImportTableOrder) => {
        return <td className={'import-cell'}>
          {record?.addressString}
        </td>;
      }
    },
    {
      title: t('ordersImportPage.table.columns.addressFromFile'),
      dataIndex: 'addressFromFile',
      width: 250,
      render: (_: any, record: ImportTableOrder) => {
        return <td className={'import-cell'}>
          {record?.addressFromFile}
        </td>;
      }
    },
    {
      title: t('ordersImportPage.table.columns.phone'),
      dataIndex: 'phone',
      width: 200,
      editable: true,
      render: (_: any, record: ImportTableOrder) => {
        return <td className={'import-cell'}>
          {record?.phone}
        </td>;
      }
    },
    {
      title: t('ordersImportPage.table.columns.addressComments'),
      dataIndex: 'addressComments',
      editable: true,
      render: (_: any, record: ImportTableOrder) => {
        return <td width={150} className={'import-cell'}>
          {record?.addressComments} <br/>
        </td>;
      }
    },
    {
      title: t('ordersImportPage.table.columns.total'),
      dataIndex: 'total',
      render: (_: any, record: ImportTableOrder) => {
        return <td width={150} className={'import-cell'}>
          {record?.total}
        </td>;
      }
    },
    {
      title: t('ordersImportPage.table.columns.operation'),
      dataIndex: 'operation',
      render: (_: any, record: ImportTableOrder) => {
        return <OperationsColumn
          record={record}
          isEditing={isEditing}
          validationStatus={validationStatus}
          save={save}
          cancel={cancel}
          editingKey={editingKey}
          hasAnyRole={hasAnyRole}
          editOrder={editOrder}
          deleteOrder={deleteOrder} />
      },
    },
  ];

  return columns.map((col) => {
    if (!col.editable) {
      return {
        ...col,
        onCell: (record: TableOrder) => ({
          record,
          form: form,
        }),
      };
    }

    return {
      ...col,
      onCell: (record: ImportTableOrder) => {

        return {
          key: record,
          record,
          setStateFullFields,
          inputType: 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          form: form,
          setValidationStatus: updateValidationStatus,
          editing: isEditing(record),
          cities: cities,
          fetchCities
        };
      },

    };
  });
}
export const getSortedTableOrders = (importForm, cities, isRowValid) => {
  return importForm?.orders?.map((orderItem: OrderItem) => {
    const initialOrder = importForm?.initialOrders?.find(initOrder => initOrder?.id === orderItem?.id);
    const newOrder = {
      key: orderItem?.id,
      id: orderItem?.id,
      createDate: orderItem?.createDate,
      Sender: orderItem?.deliverySender?.company?.name,
      total: orderItem?.apartmentsTotal,
      Receiver: orderItem?.deliveryReceiver?.company?.name,
      phone: orderItem?.deliveryReceiver?.phone || '',
      city: cities.find(city => city?.id === orderItem?.deliveryReceiver?.address?.cityId)?.name || '',
      addressString: orderItem?.deliveryReceiver?.address?.addressString || '',
      isFixed: !!orderItem?.isFixed,
      addressFromFile: initialOrder?.addressFromFile || '',
      addressComments: orderItem?.deliveryReceiver?.address?.addressComment || '',
    }
    return {
      ...newOrder,
      isValid: isRowValid(newOrder),
    };
  }).sort((a,b) => {
    if (hasEmptyFields(a) && hasEmptyFields(b)) return 0;
    if (hasEmptyFields(a)) return -1;
    if (hasEmptyFields(b)) return 1;

    if (a.isFixed && !b.isFixed) return -1;
    if (!a.isFixed && b.isFixed) return 1;

    return a.id - b.id
  })
}

export const getRowClassName = (record: ImportTableOrder, cities) => {
  if (hasEmptyFields(record, cities)) return 'import-row-error' ;
  if (record.isFixed) return 'import-row-fixed';
  return  '';
};

