import {useLocation} from 'react-router-dom'
import {PencilSquare} from 'react-bootstrap-icons'
import React, {useState} from 'react'
import {observer} from 'mobx-react-lite'
import {Spinner} from 'react-bootstrap'

import './Sidebar.scss'
import useAuth from '@/common/hooks/useAuth'
import {Roles} from '@/common/constants/roles'
import UserProfile from './components/UserCard'
import NavItem from './components/NavItem'
import logo from '@/assets/icons/logo/Logo.svg';
import textUp from '@/assets/icons/logo/Soft.svg';
import textDown from '@/assets/icons/logo/Craft.svg';

import { useTranslation } from 'react-i18next';

const Sidebar = observer(() => {
    const { user, signOut, hasRole, hasAnyRole, isLoading } = useAuth()
    const { pathname } = useLocation()
    const [showNav, setShowNav] = useState(true)
  const {t} = useTranslation()
    return (
        <div id="nav-bar" style={{width: !showNav ? '18.125vw' : '5.7vw'}}>
            <input id="nav-toggle" type="checkbox"
                   readOnly={true}
                   checked={showNav}
            />
            <div id="nav-header">
              <div className='logo'>
                <img src={logo} className="logo-img" alt='Logo' />
                <div className="logo-body">
                  <img src={textUp} className={`textUp ${showNav && 'd-none'}`} alt='Soft' />
                  <img src={textDown} className={`textDown ${showNav && 'opacity-0'}`} alt='Craft' />
                </div>
              </div>
              <label htmlFor="nav-toggle" onClick={() => setShowNav(!showNav)}>
                <span id="nav-toggle-burger"></span>
              </label>
                <hr/>
            </div>
            <div id="nav-content">
              {hasAnyRole([Roles.Client])&& (
                <div className="nav-button">
                  <NavItem
                    text={t('sidebar.home')}
                    icon={
                      <svg className="fas" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path  d="M6.01398 1.89312L2.42065 4.69312C1.82065 5.15978 1.33398 6.15312 1.33398 6.90645V11.8464C1.33398 13.3931 2.59398 14.6598 4.14065 14.6598H11.8607C13.4073 14.6598 14.6673 13.3931 14.6673 11.8531V6.99978C14.6673 6.19312 14.1273 5.15978 13.4673 4.69978L9.34732 1.81312C8.41398 1.15978 6.91398 1.19312 6.01398 1.89312Z" stroke="#1B2149" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M8 11.9932V9.99316" stroke="#1B2149"  stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    }
                    currentPath={pathname}
                    toPath="/"
                  />
                </div>
              )}
                <div className="nav-button">
                    <NavItem
                        text={t('sidebar.orders')}
                        icon={
                          <svg className="fas" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.00049 9.33325H8.66716C9.40049 9.33325 10.0005 8.73325 10.0005 7.99992V1.33325H4.00049C3.00049 1.33325 2.12716 1.88658 1.67383 2.69991" stroke="currentColor"  stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M1.33398 11.3333C1.33398 12.4399 2.22732 13.3333 3.33398 13.3333H4.00065C4.00065 12.5999 4.60065 11.9999 5.33398 11.9999C6.06732 11.9999 6.66732 12.5999 6.66732 13.3333H9.33398C9.33398 12.5999 9.93398 11.9999 10.6673 11.9999C11.4007 11.9999 12.0007 12.5999 12.0007 13.3333H12.6673C13.774 13.3333 14.6673 12.4399 14.6673 11.3333V9.33325H12.6673C12.3007 9.33325 12.0007 9.03325 12.0007 8.66659V6.66659C12.0007 6.29992 12.3007 5.99992 12.6673 5.99992H13.5273L12.3873 4.00659C12.1473 3.59326 11.7073 3.33325 11.2273 3.33325H10.0007V7.99992C10.0007 8.73325 9.40065 9.33325 8.66732 9.33325H8.00065" stroke="currentColor"  stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M5.33333 14.6667C6.06971 14.6667 6.66667 14.0697 6.66667 13.3333C6.66667 12.597 6.06971 12 5.33333 12C4.59695 12 4 12.597 4 13.3333C4 14.0697 4.59695 14.6667 5.33333 14.6667Z" stroke="currentColor"  stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10.6673 14.6667C11.4037 14.6667 12.0007 14.0697 12.0007 13.3333C12.0007 12.597 11.4037 12 10.6673 12C9.93094 12 9.33398 12.597 9.33398 13.3333C9.33398 14.0697 9.93094 14.6667 10.6673 14.6667Z" stroke="currentColor"  stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M14.6667 8V9.33333H12.6667C12.3 9.33333 12 9.03333 12 8.66667V6.66667C12 6.3 12.3 6 12.6667 6H13.5267L14.6667 8Z" stroke="currentColor"  stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M1.33398 5.33325H5.33398" stroke="currentColor"  stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M1.33398 7.33325H4.00065" stroke="currentColor"  stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M1.33398 9.33325H2.66732" stroke="currentColor"  stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        }
                        currentPath={pathname}
                        toPath="/orders"
                    />
                </div>
              {hasAnyRole([Roles.Manager, Roles.Admin]) && (
                <div className='nav-button'>
                  <NavItem
                    text={t('sidebar.clients')}
                    icon={
                      <svg className="fas" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.10671 7.24634C6.04004 7.23967 5.96004 7.23967 5.88671 7.24634C4.30004 7.19301 3.04004 5.89301 3.04004 4.29301C3.04004 2.65967 4.36004 1.33301 6.00004 1.33301C7.63337 1.33301 8.96004 2.65967 8.96004 4.29301C8.95337 5.89301 7.69337 7.19301 6.10671 7.24634Z" stroke="#1B2149" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10.9399 2.66699C12.2333 2.66699 13.2733 3.71366 13.2733 5.00033C13.2733 6.26033 12.2733 7.28699 11.0266 7.33366C10.9733 7.32699 10.9133 7.32699 10.8533 7.33366" stroke="#1B2149" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M2.77323 9.70699C1.1599 10.787 1.1599 12.547 2.77323 13.6203C4.60657 14.847 7.61323 14.847 9.44657 13.6203C11.0599 12.5403 11.0599 10.7803 9.44657 9.70699C7.6199 8.48699 4.61323 8.48699 2.77323 9.70699Z" stroke="#1B2149" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12.2268 13.333C12.7068 13.233 13.1601 13.0397 13.5335 12.753C14.5735 11.973 14.5735 10.6863 13.5335 9.90634C13.1668 9.62634 12.7201 9.43967 12.2468 9.33301" stroke="#1B2149" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>

                    }
                    currentPath={pathname}
                    toPath='/client-handbook'
                  />
                </div>
              )
              }
                <div className="nav-button">
                    <NavItem
                        text={t('sidebar.couriers')}
                        icon={
                          <svg className="fas" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.6667 10C14.6667 12.58 12.58 14.6667 10 14.6667L10.7 13.5" stroke="#1B2149" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M1.33398 6.00016C1.33398 3.42016 3.42065 1.3335 6.00065 1.3335L5.30065 2.50016" stroke="#1B2149" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M9.13281 2.9668L11.7861 4.50012L14.4128 2.97347" stroke="#1B2149" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M11.7871 7.21366V4.49365" stroke="#1B2149" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M11.1604 1.4735L9.56038 2.36013C9.20038 2.56013 8.90039 3.0668 8.90039 3.48013V5.17349C8.90039 5.58682 9.19372 6.09348 9.56038 6.29348L11.1604 7.18016C11.5004 7.37349 12.0604 7.37349 12.4071 7.18016L14.007 6.29348C14.367 6.09348 14.6671 5.58682 14.6671 5.17349V3.48013C14.6671 3.0668 14.3737 2.56013 14.007 2.36013L12.4071 1.4735C12.0671 1.28683 11.5071 1.28683 11.1604 1.4735Z" stroke="#1B2149" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M1.56641 10.3003L4.21307 11.8336L6.84641 10.307" stroke="#1B2149" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M4.21289 14.5472V11.8271" stroke="#1B2149" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M3.59398 8.8065L1.99399 9.69314C1.63399 9.89314 1.33398 10.3998 1.33398 10.8131V12.5065C1.33398 12.9198 1.62732 13.4265 1.99399 13.6265L3.59398 14.5132C3.93398 14.7065 4.49398 14.7065 4.84065 14.5132L6.44065 13.6265C6.80065 13.4265 7.10065 12.9198 7.10065 12.5065V10.8131C7.10065 10.3998 6.80732 9.89314 6.44065 9.69314L4.84065 8.8065C4.49398 8.61984 3.93398 8.61984 3.59398 8.8065Z" stroke="#1B2149" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        }
                        currentPath={pathname}
                        toPath="/couriers"
                        show={hasAnyRole([Roles.Manager, Roles.Admin])}
                    />
                </div>
                <div className="nav-button">
                    <NavItem
                        text={t('sidebar.reports')}
                        icon={
                          <svg className="fas" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.33325 8.1333H9.99992" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M5.33325 10.7998H8.25325" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M6.66659 4.00016H9.33325C10.6666 4.00016 10.6666 3.3335 10.6666 2.66683C10.6666 1.3335 9.99992 1.3335 9.33325 1.3335H6.66659C5.99992 1.3335 5.33325 1.3335 5.33325 2.66683C5.33325 4.00016 5.99992 4.00016 6.66659 4.00016Z" stroke="currentColor"  stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10.6667 2.68018C12.8867 2.80018 14 3.62018 14 6.66684V10.6668C14 13.3335 13.3333 14.6668 10 14.6668H6C2.66667 14.6668 2 13.3335 2 10.6668V6.66684C2 3.62684 3.11333 2.80018 5.33333 2.68018" stroke="currentColor"  stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        }
                        currentPath={pathname}
                        toPath="/reports"
                        show={hasAnyRole([Roles.Manager, Roles.Admin])}
                    />
                </div>
                <div id="nav-content-highlight"></div>
            </div>

            <div className="nav-footer" id="nav-footer">
                <div
                    className="nav-button d-flex flex-column justify-content-end"
                >
                    <NavItem
                        text={t('sidebar.updateData')}
                        icon={<PencilSquare className="fas"/>}
                        currentPath={pathname}
                        show={hasRole(Roles.Client)}
                        toPath="/onboard"
                    />
                </div>

                {/*    /!* User *!/*/}
                    <div className="nav-button mt-auto"
                         style={{justifyContent: 'flex-start'}}
                    >
                        {isLoading ? (
                            <div className="d-flex align-items-center justify-content-center p-3">
                                <Spinner/>
                            </div>
                        ) : (
                            <UserProfile showNav={showNav} user={user} onSignOut={signOut}/>
                        )}
                    </div>
            </div>
        </div>
    )
})

export default Sidebar
