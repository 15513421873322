import React from 'react';
import logo from '@/assets/icons/logo/Logo.svg';
import textUp from '@/assets/icons/logo/Soft.svg';
import textDown from '@/assets/icons/logo/Craft.svg';

const Logo = () => {
  return (
    <div className='logo'>
      <img src={logo} className="logo-img" alt='Logo' />
      <div className="logo-body">
        <img src={textUp} className={`textUp`} alt='Soft' />
        <img src={textDown} className={`textDown`} alt='Craft' />
      </div>
    </div>
  );
};

export default Logo;