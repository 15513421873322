import { FC } from 'react';
import { deliveryProviderTypeEnum, OrderItem } from '@/common/models/order';
import Select from 'react-select';
import { useMutation } from 'react-query';
import orderService from '@/common/api/OrderService';
import { t } from 'i18next';
import { notification } from 'antd';
import { ResponseError } from 'superagent';

interface OrderProviderSelectProps {
  selectedOrders: OrderItem[];
  refetch: () => void;
}

export const OrderProviderSelect: FC<OrderProviderSelectProps> = ({
    selectedOrders,
    refetch,
  }) => {
  const options = [
      {value: deliveryProviderTypeEnum.OWN_DELIVERY, label: t('ordersPage.details.courier') },
      {value: deliveryProviderTypeEnum.THIRD_PARTY_DELIVERY, label: t('ordersPage.details.qazPost') }
  ];

  const {mutate: changeDeliveryProviderMutate} = useMutation('', (deliveryProviderType: deliveryProviderTypeEnum) => {
    return orderService.changeDeliveryProvider(selectedOrders.map(order => ({
      orderItemId: order.id,
      deliveryProviderType,
    }))).then(res => res.data);
  }, {
    onSuccess: () => {refetch()},
    onError: (error: ResponseError) =>
      notification.error({ message: error?.response?.body?.errorMessage })
  })




  return <Select
    menuPlacement={'top'}
    className='basic-single mx-2'
    classNamePrefix="select"
    options={options}
    placeholder={t('ordersPage.details.selectProviderType')}
    onChange={(value) => {changeDeliveryProviderMutate(value.value)}}
  />
}