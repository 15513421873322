import React, { FC, useRef, useState } from 'react'
import { Camera } from 'react-camera-pro'
import { UploadFile, Image } from 'antd'
import { useTranslation } from 'react-i18next'

const CameraCapture: FC<CameraCaptureProps> = ({ onShot, onClose, isAutoClose }) => {
    const { t } = useTranslation()

    const camera = useRef(null)
    const [previewFiles, setPreviewFiles] = useState<string[]>([])
    const [isFlashEnabled, setIsFlashEnabled] = useState(false)

    const handleCameraShot = async () => {
        const image = await takePhotoAsync()

        if (isAutoClose) {
            return handleCameraClose()
        }

        setPreviewFiles([...previewFiles, image])
        onShot({ uid: '', name: `camera-${image.substring(20, 25)}`, url: image })
    }

    const takePhotoAsync = async () => {
        if (isFlashEnabled) {
            camera.current.toggleTorch()
            await new Promise((resolve) => setTimeout(resolve, 1000))
        }

        const fileBase64: string = await camera.current.takePhoto()

        if (isFlashEnabled) {
            await new Promise((resolve) => setTimeout(resolve, 200));
            camera.current.toggleTorch();
        }

        return fileBase64
    }

    const handleCameraClose = () => {
        setPreviewFiles([])
        onClose()
    }

    return (
        <div className="camera-container">
            <div className="camera-top-bar">
                <span
                    className={`flash-btn ${isFlashEnabled && 'active'}`}
                    onClick={() => setIsFlashEnabled(!isFlashEnabled)}
                >
                    {t('telegram.pages.orderAction.buttons.flashToggleBtn')}
                </span>
            </div>
            <div>
                <Camera
                    facingMode="environment" 
                    ref={camera} 
                    errorMessages={{}}
                />
            </div>
            <div className="camera-preview-bar">
                {previewFiles.map((item, index) => (
                    <div className="px-2">
                        <Image
                            className="border"
                            key={index}
                            width={48}
                            src={item}
                        />
                    </div>
                ))}
            </div>
            <div className="camera-control-bar">
                <button 
                    className="camera-shoot-btn" 
                    onClick={() => handleCameraShot()}
                />
                <span
                    className="camera-close-btn"
                    onClick={handleCameraClose}
                >
                    {t('telegram.pages.orderAction.buttons.closeCameraBtn')}
                </span>
            </div>
        </div>
    )
}

interface CameraCaptureProps {
    onShot: (file: UploadFile) => void,
    onClose: () => void,
    isAutoClose: boolean,
}

export default CameraCapture