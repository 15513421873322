import React, { FC } from 'react';
import { useTranslation } from 'react-i18next'

import { DeliveryProcessType } from '@/common/constants/deliveryProcess'
import { Button, MenuProps, notification } from 'antd';
import { Dropdown } from 'antd';
import { useMutation } from 'react-query';
import orderService from '@/common/api/OrderService';
import { deliveryProviderTypeEnum } from '@/common/models/order';
import { ResponseError } from 'superagent';


const ENABLE_STATUS = ['NEW', 'READY_FOR_PICKUP', 'PICKUP_COURIER_ASSIGNED', 'ON_WAY_TO_WAREHOUSE', 'DELIVERED_TO_WAREHOUSE', 'ACCEPTED_BY_WAREHOUSE', 'MARKING_AT_WAREHOUSE', 'MARKED_AT_WAREHOUSE', 'SORTING_AT_WAREHOUSE', 'SORTED_AT_WAREHOUSE']

export const OrderActionDropdown: FC<OrderActionDropdownProps> = ({ status, error, deliveryProcessType, isLoading = false, isSuccess = false, disabled = false,
                                 // onClick = () => {},
                                 onDetailsClick = () => {}, orderId, refetch }) => {
    const { t } = useTranslation()
    const {mutate: changeDeliveryProviderMutate} = useMutation('', (deliveryProviderType: deliveryProviderTypeEnum) => {
        return orderService.changeDeliveryProvider([{orderItemId: orderId, deliveryProviderType:  deliveryProviderType}]).then(res => res.data);
    }, {
        onSuccess: () => {refetch()},
        onError: (error: ResponseError) =>
          notification.error({ message: error?.response?.body?.errorMessage })
    });

    const items: MenuProps['items'] = [
        {key: '1', label: t('ordersPage.details.details'), onClick: () => onDetailsClick()},
        {
            key: '2',
            label: t('ordersPage.details.deliveryType'),
            disabled: !ENABLE_STATUS.includes(status),
            children: [
                {key: '2-1', label: t('ordersPage.details.courier'),
                  // disabled: !ENABLE_COURIER.includes(deliveryProcessType),
                  onClick: () => changeDeliveryProviderMutate(deliveryProviderTypeEnum.OWN_DELIVERY) },
                {key: '2-2', label: t('ordersPage.details.qazPost'),
                  // disabled: !ENABLE_QAZ_POST.includes(deliveryProcessType),
                  onClick: () => changeDeliveryProviderMutate(deliveryProviderTypeEnum.THIRD_PARTY_DELIVERY)},
            ]
        }
    ];


    return (
      <Dropdown menu={{ items }}  >
          <Button onClick={() => {onDetailsClick()}}>...</Button>
      </Dropdown>
    )
}

interface OrderActionDropdownProps {
    status: string,
    deliveryProcessType?: DeliveryProcessType,
    disabled?: boolean,
    error?: any,
    isSuccess?: boolean,
    isLoading?: boolean,
    onClick: (event: string) => void,
    onDetailsClick: () => void,
    orderId: number,
    refetch: () => void,
};