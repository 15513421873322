import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Form } from 'antd';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';

import s from './index.module.scss';

import Text from '@/components/controls/text/antText';
import Input from '@/components/controls/antInput/AntInput';
import useAuth from '@/common/hooks/useAuth';
import Button from '@/components/buttons/antButton';
import backgroundLogo from '@/assets/auth/login-background.svg';
import Logo from '@/components/logo/Logo';
import Heading from '@/components/controls/heading/antHeading';
import { textColors } from '@/common/utils/utilTypes';
import Label from '@/components/controls/antLabel/antLabel';
import Help from '@/components/controls/antHelp/antHelp';
import loginIcon from '@/assets/icons/auth/login.svg'
import eyeIcon from '@/assets/icons/auth/eye.svg'
import eyeSlash from '@/assets/icons/auth/eyeSlash.svg'


const LoginPage = observer(() => {
  const { t } = useTranslation();
  const { mutate: signIn, isLoading } = useMutation(['signIn'],
    ({ username, password }: { username: string, password: string }) => auth.signIn(username, password),
    {
      onError: () => {
        form.setFields([
          {
            name: 'username',
            errors: [t('authPage.error.incorrectLogin')],
          },
          {
            name: 'password',
            errors: [t('authPage.error.incorrectPassword')],
          },
        ]);
      },
    },
  );
  const auth = useAuth();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [form] = Form.useForm();

  if (auth.isAuthenticated()) return <Navigate to='/' />;

  const onFinish = (values) => {
    signIn(values);
  };

  const toggleShowPassword = () => {
    setShowPassword(prev => !prev);
  };

  return (
    <div className={s['login-container']}>
      <div className={s['login-divider']}></div>
      <img
        src={backgroundLogo}
        className={s['login-background-image']}
        alt='login-background'
      />
      <Form
        layout='vertical'
        form={form}
        requiredMark="optional"
        name='login'
        initialValues={{ remember: true }}
        className={s['login-form']}
        onFinish={onFinish}
      >
        <Logo />
        <Heading
          size={'h1'}
          className={s['login-header']}
        >
          {t('authPage.authorization')}
        </Heading>
        <Heading
          size={'h3'}
          color={'contentDop'}
          className={s['login-enter-data']}
        >
          {t('authPage.enterData')}
        </Heading>
        <Form.Item
          name='username'
          className={s['auth-login']}
          rules={[{ required: true, message: t('authPage.error.emptyLogin') }]}
          label={<Label required>{t('authPage.login')}</Label>}
          help={
            form.isFieldTouched('username') && form.getFieldError('username').length
              ? <Help>{form.getFieldError('username')[0]}</Help>
              : null
          }
        >
          <Input
            style={{ marginTop: '5px' }}
            suffix={<img src={loginIcon} alt={'loginIcon'}/>}
            placeholder={t('authPage.placeholder.login')}
            required
            label={t('authPage.login')}
          />
        </Form.Item>
        <Form.Item
          name='password'
          className={s['auth-password']}
          rules={[{ required: true, message: t('authPage.error.emptyPassword') }]}
          label={<Label required >{t('authPage.password')}</Label>}
          help={
            form.isFieldTouched('password') && form.getFieldError('password').length
              ? <Help>{form.getFieldError('password')[0]}</Help>
              : null
          }
        >
          <Input
            style={{ marginTop: '8px' }}
            type={showPassword ? 'text' : 'password'}
            suffix={showPassword ?
              (
                <img
                  src={eyeIcon} alt={'eyeIcon'}
                  onClick={toggleShowPassword}
                  style={{ color: textColors.contentDopLight, cursor: 'pointer' }}
                />
              ) :
              (
                <img
                  src={eyeSlash} alt={'eyeSlash'}
                  onClick={toggleShowPassword}
                  style={{ color: textColors.contentDopLight, cursor: 'pointer' }}
                />
              )}
            placeholder={t('authPage.placeholder.password')}
            required={true}
            label={t('authPage.password')}
          />
        </Form.Item>
        <Form.Item
          style={{ width: '300px', height: '45px !important', marginBottom: 0 }}
        >
          <Button loading={isLoading} styles={{ width: '100%' }} block type={'primary'} htmlType='submit'>
            {isLoading ? (
              <Text color={'white'}>{t('api.messages.loading')}...</Text>
            ) : (
              <Text color={'white'}>{t('authPage.signIn')}</Text>
            )}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
});

export default LoginPage;