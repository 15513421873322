export const ruTelegram = {
  "pages": {
    "orderAction": {
      "user": {
        "greeting": "Привет"
      },
      "counter": {
        "total": "Всего",
        "orderItem": "доставка",
        "orderItems": "доставок"
      },
      "progressLabels": {
        "loading": "Загрузка заказов",
        "loadingSingle": "Получение данных",
        "noData": "Нет заказов"
      },
      "commentPlaceholder": "Укажите подробную информацию",
      "order": "Заказ",
      "orderId": "Номер заказа",
      "orderItemId": "Номер доставки",
      "orderItem": "Доставка",
      "deliverySender": "Отправитель",
      "deliverySenderAddress": "Адрес отправителя",
      "deliveryReceiver": "Получатель",
      "deliveryReceiverAddress": "Адрес получателя",
      "comments": "Комментарии",
      "noData": "Нет данных",
      "confirmAction": "Подтвердите действие: {{event}}",
      "confirmRemoveTitle": "Удаление вложения: {{name}}",
      "confirmRemove": "Подтвердите удаление вложения",
      "attachments": "Вложения",
      "noAttachments": "Нет вложений",
      "uploadedCount": "Выбрано",
      "buttons": {
        "confirmBtn": "Подтвердить",
        "openCameraBtn": "Открыть камеру",
        "closeCameraBtn": "Закрыть",
        "flashToggleBtn": "Вспышка",
        "uploadBtn": "Выбрать",
        "sendBtn": "Отправить",
        "goBackBtn": "Вернуться назад",
      }
    }
  }
}