import React, { FC } from 'react';
import { Input, InputProps } from 'antd';
import clsx from 'clsx';

import s from './index.module.scss';

const AntInput: FC<AntInputProps> = ({ label = "", type = 'text', required = false, className = '', ...props }) => {
  return (
    <Input
      type={type}
      rootClassName={clsx(s['custom-ant-input'], className)}
      {...props}
    />
  );
};

interface AntInputProps extends InputProps{
  label?: string,
  type?: string,
  required?: boolean,
  className?: string,
}

export default AntInput;