import React, { CSSProperties, FC, ReactNode } from 'react';
import { Typography } from 'antd';

import {
  textColors,
  textColorVariants,
  textSizes,
  textSizeVariants,
  textWeights,
  textWeightVariants,
} from '@/common/utils/utilTypes';


const AntText: FC<AntTextProps> = ({ color = '', weight = '', size = '', style = {}, children, ...props }) => {
  return (
    <>
      <Typography.Text
        style={{
          color: textColors[color] || color || textColors.contentDopDark,
          fontWeight: textWeights[weight] || weight || textWeights.regular,
          fontSize: textSizes[size] || size || textSizes.body,
          padding: 0,
          margin: 0,
          ...style,
        }}
        {...props}
      >
        {children}
      </Typography.Text>
    </>
  );
};

export interface AntTextProps {
  color?: keyof typeof textColorVariants | string,
  weight?: keyof typeof textWeightVariants | string,
  size?: keyof typeof textSizeVariants | string,
  style?: CSSProperties,
  children?: ReactNode | ReactNode[];
}

export default AntText;