export type setShowAlert = ({show, message, isError}:  showAlert) => void

export type showAlert = {
  show: boolean,
  message: string,
  isError?: boolean
}

export enum textColorVariants {
  'contentMain',
  'contentDrop',
  'contentDop',
  'contentDopLight',
  'contentDopDark',
  'accentMain',
  'accentMainLight',
  'accentGreen',
  'accentGreenLight',
  'accentRed',
  'accentRedLight',
  'accentOrange',
  'accentOrangeLight',
  'buttonHover',
  'buttonActive',
}

export enum textSizeVariants {
  'small', 'body'
}

export const headingSizeVariants = {
  'h1': 1,
  'h2': 2,
  'h3': 3,
  'h4': 4,
  'h5': 5,
}

export enum textWeightVariants {
  'medium',
  'regular',
  'semiBold',
  'bold'

}

export const textColors = {
  'contentMain': '#1B2149',
  'contentDrop': '#969AB4',
  'contentDop': '#969AB4',
  'contentDopLight': '#C3C8E5',
  'contentDopDark': '#4F567E',
  'accentMain': '#7B57DF',
  'accentMainLight': '#B3A1E5',
  'accentGreen': '#5DC983',
  'accentGreenLight': '#E9F7EE',
  'accentRed': '#EB5769',
  'accentRedLight': '#FDEEF0',
  'accentOrange': '#F2AF4C',
  'accentOrangeLight': '#FCF3E6',
  'buttonHover': '#9576EB',
  'buttonActive': '#5E3AC2',
};
export const textSizes = {
  'small': '14px',
  'body': '16px',
};
export const headingSizes = {
  'h1': '48px',
  'h2': '32px',
  'h3': '24px',
};
export const textWeights = {
  'medium': '500',
  'regular': '400',
  'semiBold': '600',
  'bold': '700',
};

export enum buttonTypes {
  'primary', 'error', 'default'
}