import React, { FC } from 'react';
import { OrderItem, OrderItemChange } from '@/common/models/order'

import './timeline.css'
import { Spin, Timeline } from 'antd';
import { convertToTimeZone, getDeliveryPartyCity } from '@/common/utils/utils';
import { Formats } from '@/common/constants/dateFormat';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleOutlined, HistoryOutlined, LoadingOutlined } from '@ant-design/icons';
import activeHistoryIcon from '@/assets/icons/order/activeHistory.svg'
import historyIcon from '@/assets/icons/order/history.svg'
import Paragraph from '@/components/controls/paragraph/AntParagraph';
import Heading from '@/components/controls/heading/antHeading';


const OrderItemChangeHistoryView: FC<OrderItemChangeHistoryViewProps> = ({ orderItemData, orderItemChanges, isLoading, error }) => {
  const { t } = useTranslation()
  const getTimeLineItems = (items: OrderItemChange[]) => items.map((item, index) => {
    return {
      dot:
        index === 0 ? (
          <img src={activeHistoryIcon} alt='activeHistoryIcon'/>
        ) : (
          <img src={historyIcon} alt='activeHistoryIcon'/>
        ),
      children:
        <>
          <Paragraph size={'10px'} color={'contentDop'} weight={'medium'} style={{ display: 'block', lineHeight: '12px', paddingTop: '7px' }}>
            <time>{convertToTimeZone(item?.createDate, false, Formats.DATE_DMY_TIME)}</time>
          </Paragraph>
          <Heading size={'h4'} color={'contentMain'} weight={'medium'} style={{ display: 'block', fontSize: '16px', fontWeight: '500', lineHeight: '22px', paddingBottom: '8px' }}>
            {t(`orderStatus.${item.status}`, {
              from_city: getDeliveryPartyCity(orderItemData.deliverySender),
              to_city: getDeliveryPartyCity(orderItemData.deliveryReceiver),
            })}
          </Heading>
          {
            item?.comments && (
              <>

                <Paragraph size={'12px'} color={'contentDop'} weight={'medium'} style={{ display: 'block', paddingBottom: '4px', lineHeight: '14.5px' }}>
                  {t(`options.orderStatus.deliveredBy`)} {item?.courier?.firstName || ''} {item?.courier?.lastName || ''}
                </Paragraph>
                <Paragraph size={'12px'} color={'contentDopDark'} weight={'medium'} style={{ display: 'block', lineHeight: '14.5px' }}>
                  {item?.comments}
                </Paragraph>
              </>
            )
          }
        </>,
    };
  });

  return (
    <>
      {
        (isLoading) && (
          <div className='d-flex flex-column justify-content-center align-items-center h-100'>
            <Spin indicator={<LoadingOutlined spin />} size='large' />
            <Heading size={'h2'} style={{ margin: '0.75rem 0' }}>{t(`options.orderStatus.loadingHistory`)}</Heading>
          </div>
        )
      }

      {
        (error) && (
          <div className='d-flex flex-column justify-content-center align-items-center h-100'>
            <div className='d-flex flex-column align-items-center'>
            <span className='text-danger mb-2'>
              <ExclamationCircleOutlined style={{ color: 'red', fontSize: '24px' }} />
            </span>
              <Heading size={'h2'} color={'accentRed'}>{t(`options.orderStatus.somethingWrong`)}</Heading>
            </div>
            <span className='text-danger my-3'>{error.message}</span>
          </div>
        )
      }

      {
        (!orderItemChanges || orderItemChanges.length < 1) && (
          <div
            className="border-dotted border-rounded-2 d-flex flex-column justify-content-center align-items-center h-100">
            <div className="d-flex flex-column align-items-center">
            <span className="text-muted mb-2">
              <HistoryOutlined style={{ color: 'gray', fontSize: '24px' }} />
            </span>
              <Heading size={'h2'} color={'contentDop'}>{t(`options.orderStatus.noChanges`)}</Heading>
            </div>
          </div>
        )
      }

      {
        (orderItemData && orderItemChanges) && (
          <Timeline
            items={getTimeLineItems(orderItemChanges)}
          />
        )
      }
    </>
  )

}

interface OrderItemChangeHistoryViewProps {
    orderItemData: OrderItem,
    orderItemChanges: OrderItemChange[],
    isLoading: boolean,
    error?: any,
}

export default OrderItemChangeHistoryView
